html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  color: #b0c4de4d;
  background: #111 0 0 / cover;
  font-family: Roboto Slab, serif;
}

#display {
  backdrop-filter: blur(4px);
  background: #11111180;
  grid-template-rows: auto 1fr 1fr auto;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  height: 100%;
  display: grid;
}

#player {
  opacity: 0;
  position: absolute;
  bottom: 4rem;
}

#time {
  grid-column: 1 / span 2;
  place-self: flex-end center;
  font-size: 8rem;
}

#details {
  text-align: center;
  text-wrap: balance;
  grid-column: 1 / span 2;
  place-self: flex-start center;
  font-size: 4.5rem;
}

#viz {
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  transition: opacity .15s ease-in-out;
  position: absolute;
}

.viz-active #viz {
  opacity: .15;
}

.control {
  color: #fff;
  font-family: inherit;
  font-size: inherit;
  opacity: .1;
  background: none;
  border: none;
  padding: .5rem;
  transition: opacity .15s ease-in-out;
  transform: scale(3);
}

.control:hover {
  opacity: .666;
}

#weather {
  transform-origin: 0 0;
  opacity: .2;
  grid-area: 1 / 1;
  align-items: center;
  gap: .25rem;
  display: flex;
}

#weather:hover {
  opacity: .666;
}

#history-icon {
  transform-origin: 100% 0;
  justify-self: flex-end;
}

#viz-control {
  transform-origin: 0 100%;
  place-self: flex-end flex-start;
}

#background {
  transform-origin: 100% 100%;
  place-self: flex-end;
  align-items: center;
  gap: .25rem;
  display: flex;
}

#background-attribution {
  color: #fff;
  font-size: .5rem;
}

.background-img {
  z-index: -1;
  opacity: .5;
  transition: opacity 3s ease-in-out;
  position: absolute;
  inset: 0;
}

.background-img.swapping {
  opacity: 0;
}

#history {
  z-index: 2;
  backdrop-filter: blur(8px);
  background-color: #0000004d;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-size: 3rem;
  list-style-type: none;
  transition: all .15s ease-in-out;
  position: absolute;
  overflow: auto;
  transform: translateX(99%);
}

#history:hover {
  transform: translateX(0%);
}

#history li {
  text-align: right;
  margin: 1rem 3rem;
}

#history a {
  color: #fff;
  text-decoration: none;
  transition: color .15s ease-in-out;
}

#history a:hover {
  color: #4682b4;
}
/*# sourceMappingURL=index.ac423140.css.map */
